import React from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Projects from './components/Projects';
import Detail from './components/Detail';
import Multimedia from './components/Multimedia';

function App() {
    return(
        <div>
            <BrowserRouter>
                <Switch>
                    <Route path={'/projects/:userId'} exact={true} component={Projects}/>
                    <Route path={'/detail'} component={Detail}/>
                    <Route path={'/multimedia'} component={Multimedia}/>
                </Switch>
            </BrowserRouter>
        </div>
    )
}

export default App;