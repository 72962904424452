import React, {useEffect, useState} from 'react';
import Back from './Back';
import axios from "axios";
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Spinner from './Spinner';
import AlertDialogSlide from "./dialogs/AlertDialogSlide";
import {filterBy} from "@progress/kendo-data-query";
import { Input } from '@progress/kendo-react-inputs';
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../es.json';
import AlertDialogSlide2 from "./dialogs/AlertDialogSlide2";
loadMessages(esMessages, 'es-ES');

function Multimedia(props) {
    let userName = props.location.state.userName;
    let asignationId = props.location.state.asignationId;
    let locationId = props.location.state.locationId;
    let userId = props.location.state.userId;
    let surveyId = props.location.state.surveyId;
    let answeredSurveyId = props.location.state.answeredSurveyId;
    let pax = props.location.state.pax;
    let locationIdTask = props.location.state.locationIdTask;
    let taskId = props.location.state.taskId;
    let projectId = props.location.state.projectId;
    let status = props.location.state.status;
    const menu = "Multimedia";
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(5);
    const [filter, setFilter] = useState({
        logic: "or",
        filters: [
            { field: "answerQuestion", operator: "contains", value: "" },
            { field: "answeredSurveyId", operator: "contains", value: "" },
            { field: "questionId", operator: "contains", value: "" },
            { field: "textQuestion", operator: "contains", value: "" }
        ]
    })

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-multimedia', {userId: userId, surveyId: surveyId, answeredSurveyId: answeredSurveyId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setLoader(false);
            });
    }, [userId, surveyId,answeredSurveyId]);

    const pageChange = (event) => {
        setSkip(event.page.skip);
        setTake(event.page.take);
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: 'answerQuestion', operator: 'contains', value: e.target.value },
                    { field: 'answeredSurveyId', operator: 'contains', value: e.target.value },
                    { field: 'questionId', operator: 'contains', value: e.target.value },
                    { field: 'textQuestion', operator: 'contains', value: e.target.value }
                    ]
                }
        );
    }

    const loadButton = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'  }}>
                    <AlertDialogSlide2 props={{
                        taskId: taskId,
                        userName: userName,
                        projectId: projectId,
                        pax: pax,
                        locationIdTask: locationIdTask,
                        userId: userId,
                        status: status,
                        menu: menu,
                        locationId: locationId,
                        asignationId: asignationId,
                        questionId: props.dataItem.questionId,
                        answeredSurveyId: props.dataItem.answeredSurveyId,
                        answerQuestion: props.dataItem.answerQuestion,
                        surveyId: surveyId
                    }}/>
                </div>
            </td>
        )
    }

    const updateButton = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'  }}>
                    <AlertDialogSlide props={{
                        taskId: taskId,
                        userName: userName,
                        projectId: projectId,
                        pax: pax,
                        locationIdTask: locationIdTask,
                        userId: userId,
                        status: status,
                        menu: menu,
                        locationId: locationId,
                        asignationId: asignationId,
                        questionId: props.dataItem.questionId,
                        answeredSurveyId: props.dataItem.answeredSurveyId,
                        answerQuestion: props.dataItem.answerQuestion,
                        surveyId: surveyId
                    }}
                    />
                </div>
            </td>
        )
    }

    return(
        <LocalizationProvider language="es-ES">
            <IntlProvider locale="es" >
        <div style={{width: '100%'}}>
            <h3 style={{textAlign: 'center', alignContent: 'center', color: '#FF995B'}}>Encuestas</h3>
            <Input
                style={{width: '100%'}}
                className={'full-space'}
                placeholder={'Buscar'}
                onChange={searchEvent}/>
                <br/>
                <br/>
            <Grid
                style={{
                    height: '350px',
                    fontSize: '12px',
                    fontFamily: 'Arial',
                    textAlignLast: 'center',
                    whiteSpace: 'break-spaces'
                }}
                data={(filterBy(data, filter)).slice(skip,take + skip)}
                skip={skip}
                take={take}
                total={data.length}
                pageable={{buttonCount: 4, pageSizes: true}}
                onPageChange={pageChange}
                resizable={true}
                >
                <GridColumn title={'Id'} field={'questionId'} width={'auto'}/>
                <GridColumn title={'Encuesta Contestada'} field={'answeredSurveyId'} width={'auto'}/>
                <GridColumn title={'Pregunta'} field={'textQuestion'} width={'auto'}/>
                <GridColumn title={'Respuesta'} field={'answerQuestion'} width={'auto'}/>
                <GridColumn title={'Cargar'} cell={loadButton} width={'auto'}/>
                <GridColumn title={'Actualizar'} cell={updateButton} width={'auto'}/>
            </Grid>
            <Back/>
            {loader && <Spinner/>}
        </div>
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default Multimedia;