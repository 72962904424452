import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Back from './Back';
import axios from 'axios';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Spinner from './Spinner';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {filterBy} from "@progress/kendo-data-query";
import {Input} from "@progress/kendo-react-inputs";
import PopUpDialog from "./dialogs/PopUpDialog";
import PopUpDialog2 from "./dialogs/PopUpDialog2";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../es.json';
loadMessages(esMessages, 'es-ES');

function Detail(props) {
    let userId = props.location.state.userId;
    let userName = props.location.state.userName;
    let projectId = props.location.state.projectId;
    let projectName = props.location.state.projectName;
    const menu = "Detail";
    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(5);
    const [filter, setFilter] = useState({
        logic: "or",
        filters: [
            { field: "answeredSurveyId", operator: "contains", value: "" },
            { field: "asignationId", operator: "contains", value: "" },
            { field: "locationId", operator: "contains", value: "" },
            { field: "locationName", operator: "contains", value: "" },
            { field: "taskNameId", operator: "contains", value: "" }
        ]
    })

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-detail',{userId: userId, projectId: projectId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setLoader(false);
            });
    }, [userId, projectId]);

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: "answeredSurveyId", operator: "contains", value: e.target.value },
                    { field: "asignationId", operator: "contains", value: e.target.value },
                    { field: "locationId", operator: "contains", value: e.target.value },
                    { field: "locationName", operator: "contains", value: e.target.value },
                    { field: "taskNameId", operator: "contains", value: e.target.value }
                ]
            }
        );
    }

    const multimediaCell = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <Link to={{pathname: '/multimedia', state: {
                            userId: userId,
                            userName: userName,
                            surveyId: props.dataItem.surveyId,
                            answeredSurveyId: props.dataItem.answeredSurveyId,
                            pax: props.dataItem.pax,
                            locationIdTask: props.dataItem.locationIdTask,
                            taskId: props.dataItem.taskId,
                            projectId: projectId,
                            status: props.dataItem.status,
                            projectName: projectName,
                            locationName: props.dataItem.locationName,
                            asignationId: props.dataItem.asignationId,
                            taskNameId: props.dataItem.taskNameId,
                            locationId: props.dataItem.locationId
                        }}}>
                        <VisibilityIcon style={{color: '#FF995B'}}>
                            <span style={{color: '#FF995B', cursor: 'pointer'}}>{props.dataItem.multimedia}</span>
                        </VisibilityIcon>
                    </Link>
                </div>
            </td>
        );
    }

    const evidential = (props) => {
        let value = props.dataItem;
        let pre = value.record;
        if (pre === 2) {
            return (
                <td>
                    <div style={{textAlign: 'center', alignContent: 'center'}}>
                        <PopUpDialog props={{
                            userId: userId,
                            userName: userName,
                            menu: menu,
                            projectId: projectId,
                            projectName: projectName,
                            surveyId: props.dataItem.surveyId,
                            answeredSurveyId: props.dataItem.answeredSurveyId,
                            pax: props.dataItem.pax,
                            locationIdTask: props.dataItem.locationIdTask,
                            taskId: props.dataItem.taskId,
                            status: props.dataItem.status,
                            locationName: props.dataItem.locationName,
                            asignationId: props.dataItem.asignationId,
                            taskNameId: props.dataItem.taskNameId,
                            locationId: props.dataItem.locationId
                        }}/>
                    </div>
                </td>
            )
        } else {
            return (
                <td>
                    <div>

                    </div>
                </td>
            )
        }
    }

    const updatePre = (props) => {
        let value = props.dataItem;
        let pre = value.record;
        if (pre === 2) {
            return (
                <td>
                    <div style={{textAlign: 'center', alignContent: 'center'}}>
                        <PopUpDialog2 props={{
                            userId: userId,
                            userName: userName,
                            menu: menu,
                            surveyId: props.dataItem.surveyId,
                            answeredSurveyId: props.dataItem.answeredSurveyId,
                            pax: props.dataItem.pax,
                            locationIdTask: props.dataItem.locationIdTask,
                            taskId: props.dataItem.taskId,
                            projectId: projectId,
                            status: props.dataItem.status,
                            projectName: projectName,
                            locationName: props.dataItem.locationName,
                            asignationId: props.dataItem.asignationId,
                            taskNameId: props.dataItem.taskNameId,
                            locationId: props.dataItem.locationId
                        }}/>
                    </div>
                </td>
            )
        }else {
            return (
                <td >
                    <div>

                    </div>
                </td>
            )
        }
    }

    return(
        <LocalizationProvider language="es-ES">
            <IntlProvider locale="es" >
        <div style={{width: '100%'}}>
            <h3 style={{textAlign: 'center', alignContent: 'center', color: '#FF995B'}}>Ubicaciones</h3>
            <div>
            <Input
                style={{width: '100%'}}
                placeholder={'Buscar'}
                onChange={searchEvent}/>
            </div>
                <br/>
                <br/>
                <div>
            <Grid
                style={{
                    height: '370px',
                    fontSize: '12px',
                    fontFamily: 'Arial',
                    textAlignLast: 'center',
                }}
                data={filterBy(data, filter).slice(skip,take + skip)}
                skip={skip}
                take={take}
                total={data.length}
                pageable={{buttonCount: 4, pageSizes: true}}
                onPageChange={pageChange}
            >
                <GridColumn title={'Id Ubicacion'} field={'locationId'} width={'auto'}/>
                <GridColumn title={'Nombre Ubicación'} field={'locationName'} width={'auto'}/>
                <GridColumn title={'Asignación'} field={'asignationId'} width={'auto'} />
                <GridColumn title={'Nombre Tarea'} field={'taskNameId'} width={'auto'} />
                <GridColumn title={'Encuesta Contestada'} field={'answeredSurveyId'} width={'auto'} />
                <GridColumn title={'Ver Pregunta'} cell={multimediaCell} width={'auto'} />
                <GridColumn title={'Cargar pre-evidencia'} cell={evidential} width={'auto'} />
                <GridColumn title={'Actualizar pre-evidencia'} cell={updatePre} width={'minWidth'} />
            </Grid>

            <Back/>
            {loader && <Spinner/>}
                </div>
        </div>
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default Detail;