import firebase from 'firebase/app'
import 'firebase/storage';


const config ={
    apiURL: process.env.REACT_APP_API_URL,
    apikey: process.env.REACT_APP_API_KEY,
    databaseURL: process.env.REACT_APP_DATABASEURL,
    projectId: process.env.REACT_APP_PROJECTID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_APPID,
}
console.log(config);
firebase.initializeApp(config)
export default firebase

