import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Grid, GridColumn} from '@progress/kendo-react-grid';
import Back from './Back';
import Spinner from './Spinner';
import AppsIcon from '@material-ui/icons/Apps';
import {filterBy} from "@progress/kendo-data-query";
import {Input} from "@progress/kendo-react-inputs";
import { IntlProvider, LocalizationProvider, loadMessages } from '@progress/kendo-react-intl';
import esMessages from '../es.json';
loadMessages(esMessages, 'es-ES');

function Projects(props) {
    const userId = atob(props.match.params.userId);
    const menu = "Projects";
    const [data, setData] = useState([]);
    const [userName, setUserName] = useState("");
    const [loader, setLoader] = useState(true);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(5);
    const [filter, setFilter] = useState({
        logic: "or",
        filters: [
            { field: "projectId", operator: "contains", value: "" },
            { field: "projectName", operator: "contains", value: "" },
            { field: "total", operator: "contains", value: "" }
        ]
    })

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-projects', {userId: userId})
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                setLoader(false);
                getUserName();
            });
    },[userId]);

    const getUserName = () =>{
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-user-name', {userId: userId})
            .then((res) => {
                setUserName(res.data.response[0].userName);
            })
            .catch(() => {
                setUserName("");
            })
            .finally(() => {

            });
    }

    const pageChange = (event) => {
        setSkip(event.page.skip)
        setTake(event.page.take)
    }

    const searchEvent = (e) => {
        setFilter(
            {
                logic: 'or',
                filters: [
                    { field: "projectId", operator: "contains", value: e.target.value },
                    { field: "projectName", operator: "contains", value: e.target.value },
                    { field: "total", operator: "contains", value: e.target.value }
                ]
            }
        );
    }

    const locationsCell = (props) => {
        return(
            <td>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <Link to={{
                        pathname: '/detail',
                        state: {
                            userId: userId,
                            userName: userName,
                            projectId: props.dataItem.projectId,
                            projectName: props.dataItem.projectName
                        }
                    }}>
                        <AppsIcon style={{color: '#FF995B'}}>
                            <span style={{color: '#FF995B', cursor: 'pointer'}}>{props.dataItem.detail}</span>
                        </AppsIcon>
                    </Link>
                </div>
            </td>
        )
    }

    return (
        <LocalizationProvider language="es-ES">
            <IntlProvider locale="es" >
        <div >
            <h3 style={{textAlign: 'center', color: '#FF995B'}} >Proyectos</h3>
            <Input
                style={{width: '100%'}}
                className={'full-space'}
                placeholder={'Buscar'}
                onChange={searchEvent}
            />
            <br/>
            <br/>
            <Grid
                style={{
                    height: '370px',
                    fontSize: '12px',
                    fontFamily: 'Arial',
                    textAlignLast: 'center',
                }}
                data={filterBy(data, filter).slice(skip,take + skip)}
                skip={skip}
                take={take}
                total={data.length}
                pageable={{buttonCount: 4, pageSizes: true}}
                onPageChange={pageChange}
                resizable={true}
            >
                <GridColumn title={'Id'} field={'projectId'} filterable={false} width={'auto'}/>
                <GridColumn title={'Proyecto'} field={'projectName'} width={'auto'}/>
                <GridColumn title={'Total Ubicaciones'} field={'total'} filterable={false} width={'auto'}/>
                <GridColumn title={'Detalles'} cell={locationsCell} filterable={false} width={'auto'}/>
            </Grid>

            <Back/>
            {loader && <Spinner/>}
        </div>
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default Projects;