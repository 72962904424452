import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import firebase from "../firebase";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import VideoCallIcon from '@material-ui/icons/VideoCall';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import makeStyles from "@material-ui/core/styles/makeStyles";


let extension = '';
let file = '';
let new_name = '';
let size = 0;
let sizeMb = 0;
let observations = '';
let data3 = "";
let email = "";
let infopermit = "";
let funcion = "";
let dataReasons = [];
let bit = 5;
let emailR = 8;
let nuevo = "";
let tipo = 1;
let reason = 0;


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function PopUpDialog(props) {
    let userName = props.props.userName;
    let projectId = props.props.projectId;
    let projectName = props.props.projectName;
    let menu = props.props.menu;
    let locationName = props.props.locationName;
    let asignationId = props.props.asignationId;
    let taskNameId = props.props.taskNameId
    let pax = props.props.pax;
    let ubicacionTarea = props.props.locationIdTask;
    let idproyecto = props.props.projectId;
    let idTarea = props.props.taskId;
    let answeredSurveyId = props.props.answeredSurveyId;
    let userId = props.props.userId;
    let status = props.props.status
    let locationId = props.props.locationId;
    const [data, setData] = React.useState([]);
    const [data2, setData2] = React.useState([]);
    const [data4, setData4] = React.useState([]);
    const [open, setOpen] = React.useState(false);
    const [openReason, setOpenReason] = React.useState(false);
    const [url, setURL] = React.useState("");
    const [visible, setVisible] = React.useState(true);
    const [show, setShow] = React.useState(false);
    const [add, setAdd] = React.useState("#24EE07");
    const [message, setMessage] = React.useState(false);
    const [extensionMessage, setExtensionMessage] = React.useState(false);
    const [errorMes, setErrorMes] = React.useState(false);
    const [descripcion, setDescripcion] = React.useState("");
    const [reason1, setReason1] = React.useState(0);
    const [descriptionMessage, setDescriptionMessage] = React.useState(false);
    const [reasonMessage, setReasonMessage] = React.useState(false);
    const [sizeError, setSizeError] = React.useState(false);
    const classes = useStyles();
    const name_pro = idproyecto+"-"+projectName;
    const name_loc = locationId+"-"+locationName;
    const id_asig = asignationId;
    const name_task = idTarea+"-"+taskNameId;
    const num_pax = pax;
    const answered_sur = answeredSurveyId;

    const handleClickOpen = (infopermit, userId) => {
        if(parseInt(infopermit) == parseInt(userId)){
            setOpenReason(true);
        }else{
            setErrorMes(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClose2 = () =>{
        setMessage(false);
        setExtensionMessage(false);
        setErrorMes(false);
        setSizeError(false);
        setDescriptionMessage(false);
        setReasonMessage(false);
    };

    const getPermits = () => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-user-permits', {user_id: userId, project_id: idproyecto})
            .then((res) => {
                infopermit = res.data.response[0].userId;
            })
            .catch(() => {
                infopermit = 0;
            })
            .finally(() => {
                handleClickOpen(infopermit, userId);
            });
    }

    const getFileExtension = (file) =>{
        let extend = (/[.]/.exec(file)) ? /[^.]+$/.exec(file)[0] : undefined;
        extension = extend.toLowerCase();
        return extension;
    }

    const getEmail = (userId) => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-mail', {user_id: userId})
            .then((res) => {
                setData2(res.data.response);
                data3 = res.data.response;
                email = data3[0].mail;
            })
            .catch(() => {
                setData2([]);
            })
            .finally(() => {
                email = data3[0].mail;
            });
    }

    const selectFile = (event) => {
        file = event.target.files[0];
        size = file?.size;
        sizeMb = parseInt(parseInt(size)/parseInt(1024));
        if(sizeMb <= 512000){
            getFileExtension(file.name);
            updateName(file);
            getEmail(userId);
            console.log(email);
            setShow(true);
        }else{
            setSizeError(true);
        }
    }

    const updateData = (url,visible) => {
        let parameters = {
            "answered_survey_id": answeredSurveyId,
            "file_name": new_name,
            "token_name": url,
            "user_info": ""+userId,
            "status": status,
            "extension": extension,
            "size": size,
            "observations": observations,
            "repo_multimedia_id": null,
            "type": tipo
        }
        console.log(parameters)
        setVisible(false)
        console.log(visible)
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/insert-pre', parameters)
            .then((res) => {
                setData(res.data.response);
            })
            .catch(() => {
                setData([]);
            })
            .finally(() => {
                console.log("INSERT REALIZADO");
                setBit(funcion);
            });
    }

    const sendEmail = (url) => {

        let parameters ={
            "receive_email": email,
            "message": "La multimedia,"+new_name+", ha sido cargada con éxito. URL del video: "+url+", Nombre del proyecto"+name_pro+", Nombre de la ubicacion"+name_loc+", ID de la asignacion: "+id_asig+", Nombre de la tarea: "+name_task+", PAX: "+num_pax+", ID de la encuesta contestada: "+answered_sur+". ",
            "subject": "Multimedia cargada correctamente",
            "sender_email": "soporte-upaxer@upaxer.com"
        }
        console.log(parameters);
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/send-email', parameters)
            .then((res) => {
                emailR = res.data.response;
                console.log(emailR);
                console.log(res.data.response);
            })
            .catch(() => {
                emailR = 5;
            })
            .finally(() => {
                if (emailR === 1){
                    console.log(parameters);
                    console.log("Email Enviado");
                }else{
                    console.log(parameters);
                    console.log("ERROR");
                }
            });
    }


    const loadFile = () =>{

        let extensions = ["mp4, mp3"];

        console.log(extension)
        console.log(file)
        let aux = extensions.filter(x => parseInt(x) === extension)
        if (aux.length > 0){
            setExtensionMessage(true);
            handleClose();
        }else if (extension === "mp3") {
            let bucketName = "Audios/" + pax.slice(3);
            nuevo = "Audios/"+pax.slice(3)+"/"+new_name;
            let storageRef = firebase.storage().ref(`${bucketName}/${new_name}`);
            const uploadTask = storageRef.put(file)
            setURL("");
            setMessage(true);
            setAdd("#EE0707");
            handleClose();
            uploadTask.on('state_changed', () => {
            }, error => {
            }, () => {
                firebase.storage().ref(`${bucketName}`).child(new_name).getDownloadURL().then(url => {
                    setURL(url);
                    setVisible(false);
                    funcion = "Insert mp3"
                    updateData(url, visible, funcion);
                    sendEmail(url, email);
                });
                setAdd("#EE0707");
            })
        }else if (extension === "mp4"){
                let bucketName = "Videos/"+pax.slice(3);
                nuevo = "Videos/"+pax.slice(3)+"/"+new_name;
                let storageRef = firebase.storage().ref(`${bucketName}/${new_name}`);
                const uploadTask = storageRef.put(file)
                setURL("");
                setMessage(true);
                setAdd("#EE0707");
                handleClose();
                uploadTask.on('state_changed', () => {
                }, error => {
                }, () => {
                    firebase.storage().ref(`${bucketName}`).child(new_name).getDownloadURL().then(url => {
                        setURL(url);
                        setVisible(false);
                        funcion = "Insert mp4"
                        updateData(url, visible, funcion);
                        sendEmail(url, email);
                    });
                    setAdd("#EE0707");
                })
        }else{
            setExtensionMessage(true);
        }
    }

    const setBit = (funcion) => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/insert-bit', {menu: menu, function: funcion, backup: null, current: nuevo,
            reason: reason, observations: descripcion, user: userName, user_modified: userName, asignation_id: asignationId, location_id: locationId, project_id: projectId,
            task_id: idTarea, answered_survey_id: answeredSurveyId, audit_id: null, task_detail_id: null, user_id: userId, repo_multi_id: null, task_location_id: null})
            .then((res) => {
                bit = res.data.response;
            })
            .catch(() => {
                bit = 8;
            })
            .finally(() => {
                if (bit === 1){
                    console.log("Bitacora guardada");
                    // window.location.reload();
                }else{
                    console.log("Bitacora sin guardar");
                }
            });
    }

    const updateName = () =>{
        const date  = new Date();
        let true_date = date.getFullYear() + '-'+( '0' + (date.getUTCMonth()+1)).slice(-2).toString()+ '-' + ('0' + date.getDate()).slice(-2).toString() + '-' + ('0' + date.getHours()).slice(-2).toString() + '-' + ('0' + date.getMinutes()).slice(-2).toString() + '-' + ('0' + date.getSeconds()).slice(-2).toString();
        new_name = pax + '_' + true_date + '_' + ubicacionTarea +'_' + idproyecto + '_'+ idTarea + '.' + extension;
        return new_name;
    }

    const searchEvent = (e) => {
        setDescripcion(e.target.value);
    };

    const getReasons = () => {
        axios.post(process.env.REACT_APP_API_URL + '/multimedia/surveys/get-reasons', {user_id: userId})
            .then((res) => {
                dataReasons = res.data.response;
            })
            .catch(() => {
                dataReasons = [];
            })
            .finally(() => {
                getPermits()
            });
    }

    const changeStatus = (e) => {
        setReason1(e.target.value);
        reason = (e.target.value);
        console.log(reason);
    }

    const handleCloseReason = () => {
        setReason1(0);
        reason=(0);
        setDescripcion("");
        setOpenReason(false);
    }

    const readReason = () => {
        if (reason !== 0){
            handleOpenSelectData();
        }else {
            setReasonMessage(true);
        }
    }

    const readLength = () => {
        if (descripcion.length >= 30 && reason !== 0){
            readReason();
        }else{
            setDescriptionMessage(true);
        }
    }

    const handleOpenSelectData = () => {
        setOpenReason(false);
        setOpen(true);
    }

    return (
        <div>
            <Button disabled={visible===false || add === "#EE0707"} size={"large"} style={{borderColor: add, color: add}} variant="outlined" onClick={getReasons}>
                <VideoCallIcon/>
            </Button>
            <Snackbar open={message} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="info" variant={"filled"}>
                    Solicitud en proceso, verifique en 15 min
                </Alert>
            </Snackbar>
            <Snackbar open={extensionMessage} autoHideDuration={6000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}} fontSize={'xx-large'}>
                <Alert onClose={handleClose2} severity={"error"} variant={"filled"} fontSize={'xx-large'}>
                Selecciona un archivo .mp4
                </Alert>
            </Snackbar>
            <Snackbar open={errorMes} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="error" variant={"filled"}>
                    Se necesitan permisos de llave de caja
                </Alert>
            </Snackbar>
            <Snackbar open={sizeError} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="warning" variant={"filled"}>
                    El tamaño del video debe ser menor a 500 Mb
                </Alert>
            </Snackbar>
            <Snackbar open={descriptionMessage} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="info" variant={"filled"}>
                    El comentario debe tener al menos 30 caracteres
                </Alert>
            </Snackbar>
            <Snackbar open={reasonMessage} autoHideDuration={5000} onClose={handleClose2} anchorOrigin={{horizontal: 'center', vertical: 'top'}}>
                <Alert onClose={handleClose2} severity="info" variant={"filled"}>
                    Se debe seleccionar un motivo
                </Alert>
            </Snackbar>
        

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title" style={{textAlign: 'center'}}>{"Selección de archivo"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center'}}>
                        Seleccione el archivo que desea subir
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <input accept=".mp4" type="file" id={"template"} onChange={selectFile}/>
                    <Button disabled={show===false} onClick={loadFile}>Cargar archivo</Button>
                </DialogActions>
                <div style={{textAlign: 'center', alignContent: 'center'}}>
                    <Button onClick={handleClose}>Salir</Button>
                </div>
            </Dialog>
            <Dialog
                open={openReason}
                onClose={handleCloseReason}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"INFORMACIÓN"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Antes de continuar, por favor proporcionar los siguientes datos
                    </DialogContentText>
                    <form style={{ textAlign: "center" }}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor="demo-dialog-native">Motivo</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                // value={dataReasons[getStatusIndex(selectedStatus.id, dataReasons)]}
                                value={reason1}
                                onChange={changeStatus}
                                // label="Status"
                            >
                                {/*<MenuItem value={1}>Problemas con aplicacion</MenuItem>*/}
                                {/*<MenuItem value={2}>Problemas con sincronizacion</MenuItem>*/}
                                {/*<MenuItem value={3}>Problemas con el pax</MenuItem>*/}
                                {/*<MenuItem value={4}>Error de sistema</MenuItem>*/}
                                {/*<MenuItem value={5}>Error de usuario</MenuItem>*/}
                                {/*<MenuItem value={6}>Solicitud de negocios</MenuItem>*/}
                                {/*<MenuItem value={7}>Solicitud de cliente</MenuItem>*/}
                                {dataReasons.map(reason => {
                                    return(
                                        <MenuItem key={reason.id} value={reason.id}>{reason.name}</ MenuItem>
                                    )}
                                )}
                            </Select>
                        </FormControl>
                    </form>
                    <form
                        style={{
                            textAlign: "center",
                            alignItems: "center",
                            alignContent: "center"
                        }}
                    >
                        <FormControl
                            style={{
                                textAlign: "center",
                                alignItems: "center",
                                alignContent: "center"
                            }}
                        >
                            <textarea
                                style={{ width: "100%" , heigth: "100%"}}
                                className={"full-space"}
                                placeholder={"Descripcion"}
                                onChange={searchEvent}
                                value={descripcion}
                                minLength="30"
                                maxLength="2000"
                                cols="100"
                                rows="10"
                            />
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseReason} color="primary">
                        NO
                    </Button>
                    <Button onClick={readLength} color="primary" autoFocus>
                        SI
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}