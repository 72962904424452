import React from 'react';
import {Button} from '@progress/kendo-react-buttons';

function Back() {
    return(
        <div style={{float: 'right', marginTop: '15px'}}>
            <Button primary={true} onClick={() => window.history.back()}>Regresar</Button>
        </div>
    )
}

export default Back;