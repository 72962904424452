import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import '@progress/kendo-theme-bootstrap/dist/all.css';
import './Upaxer-Bootstrap.css';
if(window.self===window.top){
    window.location.href="https://administrador.upaxer.com/";
  }
ReactDOM.render(
    <App/>,
    document.getElementById('root')
);